const on_load_payment = {
    name: 'on_load_payment',
    title: 'Оплата за загрузку',
    url: '/settings/on_load_payment',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.on_load_payment.list',
        read: 'v1.on_load_payment.read',
        create: 'v1.on_load_payment.create',
        update: 'v1.on_load_payment.update',
        delete: 'v1.on_load_payment.delete',
    },
    listColumns: [
        {
            name: 'cost',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'cost',
            sortable: true,
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
    editColumns: [
        {
            name: 'cost',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'cost',
            sortable: true,
            type: 'number',
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
};

export {
    on_load_payment,
};
